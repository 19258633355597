import React, { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Styles from './Styles.module.scss';

interface IProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    toolbarOptions?: any;
    readOnly?: boolean;
}
const GRichTextEditor = ({ value, onChange, placeholder, toolbarOptions, readOnly }: IProps) => {
    // Define default toolbar options if not provided
    const defaultToolbarOptions = useMemo(() => {
        return (
            toolbarOptions || [
                [{ header: [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike'], // Formatting buttons
                [{ list: 'ordered' }, { list: 'bullet' }], // List buttons
                ['link', 'image'], // Link and image
                ['clean'], // Clear formatting
            ]
        );
    }, [toolbarOptions]);

    const quillRef = React.useRef<any>(null);

    // Handle content change and get full document as a Delta
    const handleChange = (content: string, delta: any, source: string, editor: any) => {
        const fullDelta = editor.getContents(); // Full Delta of the document
        onChange(fullDelta); // Pass the Delta to the parent
    };

    return (
        <ReactQuill
            readOnly={readOnly ?? false}
            className={Styles.GRichTextEditor}
            modules={{
                toolbar: !readOnly ? defaultToolbarOptions : false,
            }}
            ref={quillRef}
            value={value} // Use Delta directly
            onChange={handleChange}
            placeholder={placeholder || 'Write something...'}
            theme="snow"
        />
    );
};

export default GRichTextEditor;
